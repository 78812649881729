import React, { useEffect, useRef } from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';
import './player.scss';
import VjsPlaybackRateMenuButton from './playbackRateControls/VjsPlaybackRateMenuButton';
import VjsPlaybackRateMenu from './playbackRateControls/VjsPlaybackRateMenu';
import VjsProgressRewind from './progressControls/VjsProgressRewind';
import VjsProgressSkip from './progressControls/VjsProgressSkip';
import VjsPlaylistNext from './playlistControls/VjsPlaylistNext';
import VjsPlaylistPrevious from './playlistControls/VjsPlaylistPrevious';
import { ContentEntry } from '../../types';
import isEqual from 'lodash/isEqual';
import { defaultContent } from '../../const';

export type PlayerProps = {
  options?: videojs.PlayerOptions;
  onReady?: (player: VideoJsPlayer) => void;
  content: ContentEntry;
};

export const Player: React.FC<PlayerProps> = ({ options, onReady, content }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);

  const setUpBottomControls = (player: videojs.Player) => {
    const controlBar = player.getChild('controlBar');
    if (!controlBar) {
      return;
    }
    const vjsComponent = videojs.getComponent('Component');
    const bottomBar = new vjsComponent(player);
    bottomBar.addClass('vjs-controls-bottom');
    controlBar.addChild(bottomBar, {}, 1);

    const timeCurrent = controlBar.getChild('CurrentTimeDisplay');
    const timeDivider = controlBar.getChild('TimeDivider');
    const timeDuration = controlBar.getChild('DurationDisplay');
    const timeDisplay = new vjsComponent(player);
    timeDisplay.addClass('vjs-time-display');
    if (timeCurrent && timeDivider && timeDuration) {
      timeDisplay.addChild(timeCurrent, {}, 0);
      timeDisplay.addChild(timeDivider, {}, 1);
      timeDisplay.addChild(timeDuration, {}, 2);
    }

    const playbackRateButton = new VjsPlaybackRateMenuButton(player);
    const playbackRateMenu = new VjsPlaybackRateMenu(player);

    const progressControl = controlBar.getChild('ProgressControl');
    const fullscreenToggle = controlBar.getChild('FullscreenToggle');

    progressControl && bottomBar.addChild(progressControl, {}, 0);
    fullscreenToggle && bottomBar.addChild(fullscreenToggle, {}, 3);
    if (content.remainingTimeVisibility) {
      bottomBar.addChild(timeDisplay, {}, 1);
    }
    if (content.speedUpMode) {
      bottomBar.addChild(playbackRateButton, {}, 2);
      bottomBar.addChild(playbackRateMenu, {}, 2);
    }
  };

  const setUpTopControls = (player: videojs.Player) => {
    const controlBar = player.getChild('controlBar');
    if (!controlBar) {
      return;
    }
    const vjsComponent = videojs.getComponent('Component');
    const topBar = new vjsComponent(player);
    topBar.addClass('vjs-controls-top');

    const rewindButton = new VjsProgressRewind(player, { jumpTime: content.jumpTime });
    const skipButton = new VjsProgressSkip(player, { jumpTime: content.jumpTime });
    const nextButton = new VjsPlaylistNext(player);
    const prevButton = new VjsPlaylistPrevious(player);
    const playButton = controlBar.getChild('PlayToggle');

    playButton && topBar.addChild(playButton, {}, 0);
    if (content.jumpFeature) {
      topBar.addChild(rewindButton, {}, 0);
      topBar.addChild(skipButton, {}, 2);
    }
    if (content.skipButtonFeature) {
      topBar.addChild(prevButton, {}, 0);
      topBar.addChild(nextButton, {}, 4);
    }

    controlBar.addChild(topBar, {}, 0);
  };

  useEffect(() => {
    if (isEqual(content, defaultContent)) {
      return;
    }
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
        const controlBar = player.getChild('controlBar');
        const vjsComponent = videojs.getComponent('Component');

        const gradient = new vjsComponent(player);
        gradient.addClass('vjs-controls-gradient');

        const volumePanel = player.controlBar.getChild('VolumePanel');
        // if statement just to avoid optional chaining every time it's referenced below. It should always exist
        if (controlBar) {
          setUpBottomControls(player);
          setUpTopControls(player);
          // Styling animation sync requires gradient to be A NEIGHBOUR RIGHT AFTER control bar
          const controlBarIndex = player.children_.indexOf(controlBar);
          if (volumePanel) {
            player.player().addChild(volumePanel, { inline: false }, controlBarIndex + 1);
          }
          player.player().addChild(gradient, {}, controlBarIndex + 1);
        }
      }));
    } else {
      // you can update player here [update player through props]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, videoRef, onReady, content]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div className="video-wrapper" data-vjs-player={true}>
      <video ref={videoRef} className="video-js vjs-big-play-centered" playsInline />
    </div>
  );
};
