import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import { Player } from '../../components/player';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'videojs-playlist';
import { Helmet } from 'react-helmet';
import { VideoDescriptionComponent } from '../../components/videoDescription/videoDescription.component';

import styles from './Home.module.scss';
import { useLocation } from 'react-router';
import { PlaylistItem, RawVideoSource, LocalStorageSubscribeEntry } from '../../types';
import { Playlist } from '../../components/playlist';
import {
  LocalStorageVariables,
  LocalStorageWatchedVideos,
  markVideoAsWatched,
  MIN_TIME_FOR_MARK_VIDEO_AS_WATCHED,
  POPUP_SUBSCRIBE_COUNTER,
  SECONDS_BEFORE_VIDEO_END,
  setLastWatchedVideo,
  TIME_BEFORE_VIDEO_ENDED_MARK,
  TIME_UPDATE_EVENT_FREQUENCY
} from './utils';
import { ResumeVideoProperties } from '../../App';
import { safeJSONParse, useContent } from '../../helpers';
import { CustomVideoEvents } from '../../helpers/enums';
import { sendBestEffortEvent, sendPlayerEvent, sendTrackTimeEvent } from '../../helpers/tracking/analyticsManager';
import { AnalyticsBestEffortEnum, AnalyticsPlayerEnum, AnalyticsTrackTimeEnum } from '../../helpers/tracking/enums';
import { getPlaylistHash } from '../../helpers/getPlaylistHash';
import { AdBanner } from '../../components/adBanner';

export type HomeProps = {
  setIsPopupSubscribeOpen: Function;
  isPopupSubscribeOpen: boolean;
  resumeVideoProperties: ResumeVideoProperties;
  setResumeVideoProperties: Function;
  isPopupResumeVideoOpen: boolean;
};

export const mapRawVideoIdToPlaylistItem = (item: RawVideoSource): PlaylistItem => ({
  title: item.title || '',
  description: item.description || '',
  totalVideoTime: item.totalVideoTime || '',
  sources: [
    {
      src: `https://stream.mux.com/${item.trackId}.m3u8`,
      type: 'application/x-mpegURL'
    }
  ]
});

type TimePeriod = { start: number; stop: number; isCalculated: boolean };
type PreviousVideoData = { videoIndex: number; timestamp: number };
type SendTimeWatchedReport = {
  event: AnalyticsTrackTimeEnum;
  video: RawVideoSource;
  newVideo?: RawVideoSource;
  timestamp?: number;
};

// A lot of RefObjects are used to work within event listeners - state variables are being snapshot when event listener is created
const Home: React.FC<HomeProps> = ({
  isPopupSubscribeOpen,
  setIsPopupSubscribeOpen,
  resumeVideoProperties,
  setResumeVideoProperties,
  isPopupResumeVideoOpen
}) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);
  const [watchedVideos, setWatchedVideos] = useState<Array<number>>([]);
  const watchedVideosRef = useRef<Array<number>>([]);
  const isPopupResumeVideoOpenRef = useRef(isPopupResumeVideoOpen);
  const location = useLocation();
  const { content } = useContent();
  const previousTimeOnPlayer = useRef<number>(0);
  const timePeriodsFromPlayer = useRef<TimePeriod[]>([
    {
      start: 0,
      stop: 0,
      isCalculated: false
    }
  ]);
  const playerRef = useRef<VideoJsPlayer | undefined>(undefined);
  const playlist = useMemo<Array<PlaylistItem>>(() => content.playlist.map(mapRawVideoIdToPlaylistItem), [content]);
  const videoJsOptions = useMemo<VideoJsPlayerOptions>(() => {
    return {
      // lookup the options in the docs for more options
      autoplay: content.autoPlay,
      muted: content.muteFromBeginning,
      controls: true,
      controlBar: {
        pictureInPictureToggle: false,
        remainingTimeDisplay: false,
        durationDisplay: content.remainingTimeVisibility,
        currentTimeDisplay: content.remainingTimeVisibility,
        timeDivider: content.remainingTimeVisibility,
        fullscreenToggle: content.fullscreenMode
      },
      responsive: true,
      fill: true,
      sources: []
    };
  }, [content]);
  const isLastVideoRef = useRef<boolean>(currentVideoIndex === playlist.length - 1);
  const lastVideoWatchedEventSendRef = useRef<boolean>(false);
  const previousVideoDataRef = useRef<PreviousVideoData | undefined>(undefined);
  const currentPlaylistHash = useMemo(() => getPlaylistHash(content.playlist), [content.playlist]);
  const isPopupSubscribeOpenRef = useRef<boolean>(isPopupSubscribeOpen);
  const wasPopupSubscribeOpenedThisVideoRef = useRef<boolean>(false);

  useEffect(() => {
    isPopupSubscribeOpenRef.current = isPopupSubscribeOpen;
  }, [isPopupSubscribeOpen]);

  const reportTimeWatched = async (timePeriodsFromPlayerRef: MutableRefObject<TimePeriod[]>): Promise<number> => {
    let timeWatched = 0;
    timePeriodsFromPlayerRef.current.forEach((currentPeriod, index) => {
      timeWatched += currentPeriod.stop - currentPeriod.start;
      //check every previous time period for duplications
      for (let playerTime = 0; playerTime < index; playerTime++) {
        const previousPeriod = timePeriodsFromPlayerRef.current[playerTime];
        // check if current period time from the start overlap with any previous period
        const isStartTimeInOtherPeriod =
          previousPeriod.start <= currentPeriod.start && previousPeriod.stop >= currentPeriod.start;
        // check if current period time from the end overlap with any previous period
        const isStopTimeInOtherPeriod =
          previousPeriod.start <= currentPeriod.stop && previousPeriod.stop >= currentPeriod.stop;
        if (previousPeriod.isCalculated) {
          continue;
        }
        // delete duplications on previous time periods
        if (isStartTimeInOtherPeriod && isStopTimeInOtherPeriod) {
          timeWatched -= currentPeriod.stop - currentPeriod.start;
        } else if (isStopTimeInOtherPeriod) {
          timeWatched -= previousPeriod.stop - currentPeriod.stop;
          previousPeriod.isCalculated = true;
        } else if (isStartTimeInOtherPeriod) {
          timeWatched -= previousPeriod.stop - currentPeriod.start;
          previousPeriod.isCalculated = true;
        }
      }
    });
    return timeWatched;
  };

  const sendTimeWatchedReport = async ({ event, newVideo, video, timestamp }: SendTimeWatchedReport) => {
    const trackedTime = await reportTimeWatched(timePeriodsFromPlayer);
    sendTrackTimeEvent(event, { trackedTime, video, newVideo, timestamp });
  };

  const handleTimeUpdateEvent = (player: videojs.Player) => {
    const playerCache = player.getCache();
    // @ts-ignore
    const videoIndex = player.playlist.currentIndex();
    if (!isPopupResumeVideoOpenRef.current && playerCache.currentTime > 1) {
      setLastWatchedVideo(playerCache.currentTime, videoIndex, location.pathname, currentPlaylistHash); //create in localStorage last watched video per each microsite
    }

    const isShorterThanWatchedMark = playerCache.duration < MIN_TIME_FOR_MARK_VIDEO_AS_WATCHED;
    const markAsWatched = isShorterThanWatchedMark
      ? playerCache.currentTime < playerCache.duration / 2
      : playerCache.currentTime >= MIN_TIME_FOR_MARK_VIDEO_AS_WATCHED;
    if (markAsWatched) {
      // @ts-ignore
      const videoItem = player.playlist.currentItem();
      const videosToSet = [...watchedVideosRef.current, videoItem];
      setWatchedVideos(videosToSet);
      watchedVideosRef.current = videosToSet;

      markVideoAsWatched({ videoIndex: videoItem, locationPath: location.pathname, currentPlaylistHash });
    }
    if (
      playerCache.duration - playerCache.currentTime < TIME_BEFORE_VIDEO_ENDED_MARK &&
      isLastVideoRef.current &&
      !lastVideoWatchedEventSendRef.current
    ) {
      lastVideoWatchedEventSendRef.current = true;
    }
    handleSubscribePopUp(playerCache.currentTime, playerCache.duration, location.pathname);
  };

  const recordTimeOnTimeUpdate = (player: videojs.Player) => {
    // this event is run from every .25-1s and it sets time periods from start time to end time that user actually watched
    const currentTime = player.currentTime();
    const differenceBetweenEvents = currentTime - previousTimeOnPlayer.current;
    if (
      differenceBetweenEvents <= TIME_UPDATE_EVENT_FREQUENCY &&
      differenceBetweenEvents >= 0 &&
      timePeriodsFromPlayer.current.length
    ) {
      timePeriodsFromPlayer.current[timePeriodsFromPlayer.current.length - 1].stop = currentTime;
    } else {
      timePeriodsFromPlayer.current.push({
        start: currentTime,
        stop: currentTime,
        isCalculated: false
      });
    }
    previousTimeOnPlayer.current = currentTime;
  };

  const handlePlayerReady = (player: VideoJsPlayer) => {
    playerRef.current = player;

    // @ts-ignore
    player.playlist(playlist);

    // @ts-ignore
    player.playlist.autoadvance(1);

    player.on('pause', () => {
      // @ts-ignore
      const index = player.playlist.currentIndex();
      const video = content.playlist[index];
      sendTimeWatchedReport({
        event: AnalyticsTrackTimeEnum.PLAYBACK_PAUSED,
        video,
        timestamp: player.currentTime()
      });
    });

    player.on('play', () => {
      // @ts-ignore
      const index = player.playlist.currentIndex();
      const video = content.playlist[index];
      sendTimeWatchedReport({
        event: AnalyticsTrackTimeEnum.PLAYBACK_STARTED,
        video,
        timestamp: player.currentTime()
      });
    });

    player.on('ended', () => {
      // @ts-ignore
      const videoIndex = player.playlist.currentItem();
      const videosToSet = [...watchedVideosRef.current, videoIndex];
      setWatchedVideos(videosToSet);
      watchedVideosRef.current = videosToSet;
    });

    player.on('loadstart', () => {
      // @ts-ignore
      const videoIndex = player.playlist.currentItem();
      isLastVideoRef.current = videoIndex === playlist.length - 1;
      setCurrentVideoIndex(videoIndex);
    });

    player.on('timeupdate', () => handleTimeUpdateEvent(player));

    player.on('timeupdate', () => recordTimeOnTimeUpdate(player));

    player.on(CustomVideoEvents.TIME_SKIP_FORWARD, () => {
      const timestampBefore = player.currentTime();
      player.currentTime(player.currentTime() + content.jumpTime);
      const timestamp = player.currentTime();
      // @ts-ignore
      const currentVideo = content.playlist[player.playlist.currentIndex()];
      sendPlayerEvent(AnalyticsPlayerEnum.VIDEO_BUTTON_TIME_FORWARD, {
        timestamp,
        timestampBefore,
        currentVideo
      });
    });

    player.on(CustomVideoEvents.TIME_SKIP_BACK, () => {
      const timestampBefore = player.currentTime();
      player.currentTime(player.currentTime() - content.jumpTime);
      const timestamp = player.currentTime();
      // @ts-ignore
      const currentVideo = content.playlist[player.playlist.currentIndex()];
      sendPlayerEvent(AnalyticsPlayerEnum.VIDEO_BUTTON_TIME_BACKWARD, {
        timestamp,
        timestampBefore,
        currentVideo
      });
    });

    player.on(CustomVideoEvents.VIDEO_SKIP_NEXT, () => {
      // @ts-ignore
      player.playlist.next();
    });

    player.on(CustomVideoEvents.VIDEO_SKIP_PREVIOUS, () => {
      // @ts-ignore
      player.playlist.previous();
    });

    player.on('playlistitem', async () => {
      wasPopupSubscribeOpenedThisVideoRef.current = false;
      lastVideoWatchedEventSendRef.current = false;
      // @ts-ignore
      const index = player.playlist.currentIndex();
      const newVideo = content.playlist[index];
      if (previousVideoDataRef.current) {
        sendTimeWatchedReport({
          event: AnalyticsTrackTimeEnum.VIDEO_CHANGED,
          video: content.playlist[previousVideoDataRef.current?.videoIndex],
          newVideo,
          timestamp: previousVideoDataRef.current?.timestamp
        });
        timePeriodsFromPlayer.current = [];
      }
    });

    player.on('beforeplaylistitem', async () => {
      const currentTime = player.currentTime();
      const duration = player.duration();
      const timeAndDurationDifference = duration - currentTime;
      // @ts-ignore
      const index = player.playlist.currentIndex();
      const video = content.playlist[index];
      if (timeAndDurationDifference <= TIME_BEFORE_VIDEO_ENDED_MARK) {
        sendTimeWatchedReport({ event: AnalyticsTrackTimeEnum.VIDEO_WATCHED, video });
      } else {
        sendTimeWatchedReport({
          event: AnalyticsTrackTimeEnum.VIDEO_VIEWED,
          video,
          timestamp: player.currentTime()
        });
      }
      previousVideoDataRef.current = { videoIndex: index, timestamp: player.currentTime() };
    });
  };

  const handleSubscribePopUp = (currentTime: number, duration: number, locationPath: string) => {
    if (
      isPopupSubscribeOpenRef.current ||
      wasPopupSubscribeOpenedThisVideoRef.current ||
      duration - currentTime > SECONDS_BEFORE_VIDEO_END
    ) {
      return;
    }

    try {
      const subscriptionEntry = localStorage.getItem(LocalStorageVariables.SUBSCRIPTION);
      const isEntryNull = subscriptionEntry === null;
      let subscriptionEntryArray: Array<LocalStorageSubscribeEntry> = [];
      if (!isEntryNull) {
        subscriptionEntryArray = safeJSONParse<Array<LocalStorageSubscribeEntry>>(subscriptionEntry) || [];
      }
      const locationSubscriptionEntryIndex = subscriptionEntryArray.findIndex(
        (item: LocalStorageSubscribeEntry): boolean => item.path === locationPath
      );
      const locationSubscriptionEntry: LocalStorageSubscribeEntry =
        subscriptionEntryArray[locationSubscriptionEntryIndex];
      if (locationSubscriptionEntry.count < POPUP_SUBSCRIBE_COUNTER && !locationSubscriptionEntry.signedIn) {
        subscriptionEntryArray[locationSubscriptionEntryIndex].count += 1;
        localStorage.setItem(LocalStorageVariables.SUBSCRIPTION, JSON.stringify(subscriptionEntryArray));
        wasPopupSubscribeOpenedThisVideoRef.current = true;
        setIsPopupSubscribeOpen(true);
      }
    } catch {}
  };

  const setPlaylistItem = (index: number) => {
    // @ts-ignore
    playerRef.current?.playlist.currentItem(index);
    setCurrentVideoIndex(index);
  };

  useEffect((): VoidFunction => {
    const listener = () => {
      // @ts-ignore
      const videoIndex = playerRef.current?.player_.playlist.currentIndex() || currentVideoIndex;
      const video = content.playlist[videoIndex];
      const timestamp = playerRef.current?.player_.currentTime() || 0;
      sendBestEffortEvent(AnalyticsBestEffortEnum.PAGE_UNLOAD, { video, timestamp });
    };
    window.addEventListener('unload', listener);
    return () => {
      window.removeEventListener('unload', listener);
    };
  });

  useEffect(() => {
    if (lastVideoWatchedEventSendRef.current) {
      // @ts-ignore
      const index = playerRef.current?.player_.playlist.currentIndex();
      const video = content.playlist[index];
      sendTimeWatchedReport({ event: AnalyticsTrackTimeEnum.VIDEO_WATCHED, video });
      timePeriodsFromPlayer.current = [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastVideoWatchedEventSendRef.current]);

  useEffect(() => {
    if (!isPopupResumeVideoOpen) {
      isPopupResumeVideoOpenRef.current = false;
    }
  }, [isPopupResumeVideoOpen]);

  useEffect(() => {
    const watchedVideosFromStorage = localStorage.getItem(LocalStorageVariables.WATCHED_VIDEOS);
    if (watchedVideosFromStorage) {
      safeJSONParse<Array<LocalStorageWatchedVideos>>(watchedVideosFromStorage)?.forEach(
        (item: LocalStorageWatchedVideos) => {
          if (item.path === location.pathname && item.hash === currentPlaylistHash) {
            setWatchedVideos(item.ids);
            watchedVideosRef.current = item.ids;
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVideoIndex]);

  useEffect(() => {
    if (resumeVideoProperties !== null) {
      setCurrentVideoIndex(resumeVideoProperties.id);
      // @ts-ignore
      playerRef.current?.playlist.currentItem(resumeVideoProperties);
      playerRef.current?.currentTime(resumeVideoProperties.time);
      setResumeVideoProperties(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeVideoProperties]);

  useEffect(() => {
    try {
      const subscriptionEntry = localStorage.getItem(LocalStorageVariables.SUBSCRIPTION);
      const isEntryNull = subscriptionEntry === null;
      let subscriptionEntryArray: Array<LocalStorageSubscribeEntry> = [];
      if (!isEntryNull) {
        subscriptionEntryArray = safeJSONParse<Array<LocalStorageSubscribeEntry>>(subscriptionEntry) || [];
      }
      const locationSubscriptionEntryIndex = subscriptionEntryArray.findIndex(
        (item: LocalStorageSubscribeEntry): boolean => item.path === location.pathname
      );
      const locationSubscriptionEntry: LocalStorageSubscribeEntry =
        subscriptionEntryArray[locationSubscriptionEntryIndex];
      const isLocationSubscriptionEntryDefined = !!locationSubscriptionEntry;
      if (!isLocationSubscriptionEntryDefined) {
        const newLocationEntry: LocalStorageSubscribeEntry = {
          path: location.pathname,
          count: 0,
          signedIn: false
        };
        subscriptionEntryArray.push(newLocationEntry);
        localStorage.setItem(LocalStorageVariables.SUBSCRIPTION, JSON.stringify(subscriptionEntryArray));
      }
    } catch {}
  }, [location.pathname]);

  useEffect(() => {
    // check if the player has loaded
    if (playerRef.current) {
      handlePlayerReady(playerRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <div className={styles.container}>
      {playlist[currentVideoIndex]?.title && (
        <Helmet>
          <title>{playlist[currentVideoIndex].title}</title>
        </Helmet>
      )}
      <AdBanner adBannerImg={content.adsBanner} adBannerRedirect={content.adBannerRedirect} />
      <div className={styles.pageContent}>
        <div className={styles.videoColumn}>
          <div className={styles.playerContainer}>
            <Player options={videoJsOptions} onReady={handlePlayerReady} content={content} />
          </div>
          <div className={styles.descriptionContainer}>
            <VideoDescriptionComponent content={content} currentVideoIndex={currentVideoIndex} />
          </div>
        </div>
        {!!playlist.length && (
          <Playlist
            playlist={playlist}
            setPlaylistItem={setPlaylistItem}
            currentVideoIndex={currentVideoIndex}
            watchedVideos={watchedVideos}
            playlistTitle={content.playlistTitle}
            itemsPerPage={content.itemsPerPage}
          />
        )}
      </div>
    </div>
  );
};

export default Home;
