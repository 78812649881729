// TODO: Add js script for hotjar integration
export const hotjarScriptDevelopment = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2844930,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
export const hotjarScriptProduction = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2844936,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

// Keep for now just in case turns out we need it. Configuration in Admin panel to connect UA with GA4 should be enough
export const googleTagScriptProduction = `<script async src="https://www.googletagmanager.com/gtag/js?id=G-99MZTV0T06"></script>
<script> window.dataLayer = window.dataLayer || [];
  function gtag()
  {dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-99MZTV0T06');
</script>`;

export const googleTagScriptDevelopment = `<script async src="https://www.googletagmanager.com/gtag/js?id=G-2JWJDQTH4C"></script>
<script> window.dataLayer = window.dataLayer || [];
  function gtag()
  {dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-2JWJDQTH4C');
</script>`;
