import { ContentEntry } from '../types';

export const defaultContent: ContentEntry = {
  path: '',
  autoPlay: false,
  muteFromBeginning: false,
  skipButtonFeature: false,
  fullscreenMode: false,
  speedUpMode: false,
  jumpFeature: true,
  jumpTime: 15,
  remainingTimeVisibility: false,
  partnersLogo: '',
  playingContentItemColor: '#EC1C2E',
  playlistTitle: 'More stories',
  itemsPerPage: 20,
  adsBanner: '',
  klaviyoNewsletterId: 'XfrNfj',
  playlist: [],
  socialMedia: [],
  aboutList: [
    {
      url: '',
      name: ''
    }
  ]
};
