import { useEffect, useMemo, useState } from 'react';
import './App.scss';
import { initializeMixpanel } from './helpers/tracking/mixpanel';
import isEqual from 'lodash/isEqual';

import { Layout } from './layout';
import PopupSubscribe from './components/popups/popupSubscribe';
import PopupResumeVideo from './components/popups/popupResumeVideo';
import { HeadTagsBuilder, TagToLoad } from './helpers/headTag/head-tags-builder';
import { Helmet } from 'react-helmet';
import { initializeGA } from './helpers/tracking/googleAnalitics';
import { campaignParams, registerSuper, trackPageEvents } from './helpers/tracking/analyticsManager';
import { safeJSONParse, useContent } from './helpers';
import { useLocation } from 'react-router';
import { detectDeviceInfo } from './helpers/detectDeviceInfo';
import { LocalStorageLastWatchedVideos, LocalStorageVariables } from './pages/home/utils';
import { getPlaylistHash } from './helpers/getPlaylistHash';
import { NotFound } from './pages/notFound';
import { LocalStorageSubscribeEntry } from './types';
import { defaultContent } from './const';

const POPUP_RESUME_VIDEO_LIFETIME = 5; // in seconds
export type ResumeVideoProperties = null | { id: number; time: number };
type AcceptedCookies = {
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
};

export const App: React.FC = () => {
  const [isPopupSubscribeOpen, setIsPopupSubscribeOpen] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [isPopupResumeVideoOpen, setIsPopupResumeVideoOpen] = useState<boolean>(false);
  const [resumeVideoProperties, setResumeVideoProperties] = useState<ResumeVideoProperties>(null);
  const [tagsToLoad, setTagsToLoad] = useState<Array<TagToLoad>>([]);
  const location = useLocation();
  const { content, isContentLoaded } = useContent();
  const isContentDefinedForPath = useMemo<boolean>(() => {
    return !isEqual(content, defaultContent);
  }, [content]);
  const deviceInfo = detectDeviceInfo();
  const [acceptedCookies, setAcceptedCookies] = useState<AcceptedCookies>({
    preferences: false,
    statistics: false,
    marketing: false
  });
  const currentPlaylistHash = useMemo(() => getPlaylistHash(content.playlist), [content.playlist]);

  useEffect((): VoidFunction => {
    const listener = (): void => {
      // use optional chaining to make sure that ts-ignore does not break the page when something's missing
      setAcceptedCookies({
        // @ts-ignore
        marketing: window.Cookiebot?.consent?.marketing || false,
        // @ts-ignore
        statistics: window.Cookiebot?.consent?.statistics || false,
        // @ts-ignore
        preferences: window.Cookiebot?.consent?.preferences || false
      });
    };
    window.addEventListener('CookiebotOnAccept', listener);

    return () => {
      window.removeEventListener('CookiebotOnAccept', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (acceptedCookies.statistics) {
      initializeGA();
      initializeMixpanel();
      const tags = new HeadTagsBuilder().instance;
      setTagsToLoad(tags);
    }
  }, [acceptedCookies]);

  //check if PopupResumeVideo is needed and set its lifetime
  useEffect(() => {
    const localStorageLastVideos: null | string = localStorage.getItem(LocalStorageVariables.LAST_VIDEOS);
    if (localStorageLastVideos !== null) {
      const parsedWatchedVideo = safeJSONParse<Array<LocalStorageLastWatchedVideos>>(localStorageLastVideos) || [];
      const currentPathEntry = parsedWatchedVideo.find(
        (item: LocalStorageLastWatchedVideos) => item.path === location.pathname
      );
      if (currentPathEntry && currentPathEntry.hash === currentPlaylistHash) {
        setIsPopupResumeVideoOpen(true);
        setTimeout(() => {
          setIsPopupResumeVideoOpen(false);
        }, POPUP_RESUME_VIDEO_LIFETIME * 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    const localStorageSubscriptions: null | string = localStorage.getItem(LocalStorageVariables.SUBSCRIPTION);
    if (localStorageSubscriptions !== null) {
      const parsedSubscription = safeJSONParse<Array<LocalStorageSubscribeEntry>>(localStorageSubscriptions) || [];
      const locationSubscriptionEntry = parsedSubscription.find(
        (item: LocalStorageSubscribeEntry): boolean => item.path === location.pathname
      );
      setIsSubscribed(!!locationSubscriptionEntry?.signedIn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupSubscribeOpen]);

  useEffect(() => {
    if (acceptedCookies.statistics) {
      registerSuper(content, deviceInfo);
    }
  }, [acceptedCookies, content, deviceInfo]);

  useEffect(() => {
    if (acceptedCookies.statistics) {
      campaignParams(location);
    }
  }, [location, acceptedCookies]);

  useEffect(() => {
    if (acceptedCookies.statistics) {
      trackPageEvents({ eventName: location.pathname });
    }
  }, [location.pathname, acceptedCookies]);

  if (!isContentLoaded) {
    return null;
  }

  return (
    <div className="App">
      <Helmet script={tagsToLoad} />
      {isContentDefinedForPath ? (
        <>
          <Layout
            isSubscribed={isSubscribed}
            setIsPopupSubscribeOpen={setIsPopupSubscribeOpen}
            isPopupSubscribeOpen={isPopupSubscribeOpen}
            resumeVideoProperties={resumeVideoProperties}
            setResumeVideoProperties={setResumeVideoProperties}
            isPopupResumeVideoOpen={isPopupResumeVideoOpen}
          />
          {isPopupSubscribeOpen && (
            <PopupSubscribe
              setIsPopupSubscribeOpen={setIsPopupSubscribeOpen}
              klaviyoNewsletterId={content.klaviyoNewsletterId}
            />
          )}
          {isPopupResumeVideoOpen && (
            <PopupResumeVideo
              setIsPopupResumeVideoOpen={setIsPopupResumeVideoOpen}
              setResumeVideoProperties={setResumeVideoProperties}
              currentPlaylistHash={currentPlaylistHash}
            />
          )}
        </>
      ) : (
        <NotFound />
      )}
    </div>
  );
};
