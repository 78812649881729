import React, { ReactElement } from 'react';
import { NewsletterButtonInterface } from './newsletterButton.interface';
import styles from './newsletterButton.module.scss';

export const NewsletterButtonComponent = ({ onClick }: NewsletterButtonInterface): ReactElement => {
  return (
    <div className={styles.container} onClick={onClick}>
      <p>Sign Up for Newsletter</p>
    </div>
  );
};
