import styles from './progressControls.module.scss';
import ReactDOM from 'react-dom';
import videojs from 'video.js';
import { Skip } from '../../../assets/icons';
import React from 'react';
import { CustomVideoEvents } from '../../../helpers/enums';

const vjsButton = videojs.getComponent('Button');

type VjsProgressSkipProps = {
  jumpTime: number;
};

const ProgressSkip: React.FC = () => {
  return (
    <div className={styles.progressControl}>
      <Skip />
    </div>
  );
};

class VjsProgressSkip extends vjsButton {
  private onButtonCLick = (): void => {
    this.player_.trigger(CustomVideoEvents.TIME_SKIP_FORWARD);
  };

  constructor(player: videojs.Player, props: VjsProgressSkipProps, options?: videojs.ComponentOptions) {
    super(player, options);
    this.addClass('vjs-button-progress-skip');
    this.controlText(`Skip ${props.jumpTime}s`);

    this.enableTouchActivity();
    this.on('click', (): void => {
      this.onButtonCLick();
    });
    // use tap event only when TOUCH_ENABLED is not undefined
    this.on('tap', (): void => {
      this.onButtonCLick();
    });

    /* Bind the current class context to the mount method */
    this.mount = this.mount.bind(this);

    /* When player is ready, call method to mount React component */
    player.ready(() => {
      this.mount();
    });

    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  mount() {
    ReactDOM.render(<ProgressSkip />, this.el());
  }
}

export default VjsProgressSkip;
