import styles from './playbackRateControls.module.scss';
import React, { useEffect, useState } from 'react';
import { playbackRateCLickedEvent } from '../../../const';
import videojs from 'video.js';
import ReactDOM from 'react-dom';
import { Close } from '../../../assets/icons';
import classNames from 'classnames';

const vjsComponent = videojs.getComponent('Component');
const playbackRates = [0.5, 0.75, 1, 1.25, 1.5];

type PlaybackMenuProps = {
  onItemClick: (value: number) => void;
  initialValue: number;
};

export const PlaybackRateMenu: React.FC<PlaybackMenuProps> = ({ onItemClick, initialValue }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onPlaybackRateClick = (rate: number): void => {
    onItemClick(rate);
    setIsMenuOpen(false);
  };

  const onHideClick = (): void => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener(playbackRateCLickedEvent, () => {
      setIsMenuOpen((value) => !value);
    });

    return () => {
      window.removeEventListener(playbackRateCLickedEvent, () => {
        setIsMenuOpen((value) => !value);
      });
    };
  }, []);

  return (
    <div className={classNames(styles.menuContainer, !isMenuOpen && styles.hidden)}>
      <div className={styles.hideButton} onClick={onHideClick}>
        <Close />
        <span>hide</span>
      </div>
      <form className={styles.playbackRates}>
        {playbackRates.map((item) => (
          <label key={item} className={styles.playbackRate} onClick={() => onPlaybackRateClick(item)}>
            <input
              defaultChecked={initialValue === item}
              type="radio"
              className={styles.radioInput}
              id={item.toString()}
              name="playback-rate"
              value={item.toString()}
            />
            {item !== 1 ? `${item}x` : `${item}x (normal)`}
          </label>
        ))}
      </form>
    </div>
  );
};

class VjsPlaybackRateMenu extends vjsComponent {
  // Use Window events  since videojs only affect its components like control buttons but not our custom ones.
  private onButtonCLick = (value: number): void => {
    this.player_.playbackRate(value);
  };

  constructor(player: videojs.Player, options?: videojs.ComponentOptions) {
    super(player, options);
    this.addClass('vjs-menu-playback-rate');

    /* Bind the current class context to the mount method */
    this.mount = this.mount.bind(this);

    /* When player is ready, call method to mount React component */
    player.ready(() => {
      this.mount();
    });

    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  mount() {
    ReactDOM.render(
      <PlaybackRateMenu onItemClick={this.onButtonCLick} initialValue={this.player_.playbackRate()} />,
      this.el()
    );
  }
}

export default VjsPlaybackRateMenu;
