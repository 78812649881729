import styles from './playbackRateControls.module.scss';
import ReactDOM from 'react-dom';
import videojs from 'video.js';
import { PlaybackRate } from '../../../assets/icons';
import React from 'react';
import { playbackRateCLickedEvent } from '../../../const';

const vjsButton = videojs.getComponent('Button');

const PlaybackRateMenuButton: React.FC = () => {
  return (
    <div className={styles.menuButtonContainer}>
      <PlaybackRate />
    </div>
  );
};

class VjsPlaybackRateMenuButton extends vjsButton {
  // Use Window events  since videojs only affect its components like control buttons but not our custom ones.
  private onButtonCLick = (): void => {
    window.dispatchEvent(new Event(playbackRateCLickedEvent));
  };

  constructor(player: videojs.Player, options?: videojs.ComponentOptions) {
    super(player, options);
    this.addClass('vjs-button-playback-rate');
    this.controlText('Playback rate');

    this.enableTouchActivity();
    this.on('click', (): void => {
      this.onButtonCLick();
    });
    // use tap event only when TOUCH_ENABLED is not undefined
    this.on('tap', (): void => {
      this.onButtonCLick();
    });

    /* Bind the current class context to the mount method */
    this.mount = this.mount.bind(this);

    /* When player is ready, call method to mount React component */
    player.ready(() => {
      this.mount();
    });

    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  mount() {
    ReactDOM.render(<PlaybackRateMenuButton />, this.el());
  }
}

export default VjsPlaybackRateMenuButton;
