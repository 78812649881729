import axios from 'axios';
import { ContentEntry } from '../../types';
import { STRAPI_URL } from './constants';

export const getMicrosites = async (micrositePath: string): Promise<ContentEntry | undefined> => {
  const response = await axios.get(`${STRAPI_URL}/listendot-microsites`, {
    params: { path: micrositePath }
  });
  if (response.data) {
    return response.data[0];
  } else {
    return undefined;
  }
};
