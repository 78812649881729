import { RefObject } from 'react';

export const sliderStyles = (playlistRef?: RefObject<HTMLDivElement> | null) => ({
  color: 'rgba(0,0,0,0.87)',
  height: playlistRef?.current?.clientHeight || 100,
  '& .MuiSlider-thumb': {
    width: 5,
    height: 30,
    borderRadius: 0,
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    '&:before': {
      boxShadow: '0'
    },
    '&:after': {
      width: 0,
      height: 0
    },
    '&:hover, &.Mui-focusVisible': {
      boxShadow: 0
    },
    '&.Mui-active': {
      width: 5,
      height: 30
    }
  },
  '& .MuiSlider-mark': {
    display: 'none'
  },
  '& .MuiSlider-rail': {
    opacity: 0.28,
    width: 5
  },
  '& .MuiSlider-track': {
    backgroundColor: 'transparent'
  }
});
