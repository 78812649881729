import { safeJSONParse } from '../../helpers';

export const POPUP_SUBSCRIBE_COUNTER = 1;
export const SECONDS_BEFORE_VIDEO_END = 3; //number of seconds to end of video when popup Subscribe should appear
export const MIN_TIME_FOR_MARK_VIDEO_AS_WATCHED = 30;
export const TIME_UPDATE_EVENT_FREQUENCY = 1;
export const TIME_BEFORE_VIDEO_ENDED_MARK = 10;

export enum LocalStorageVariables {
  LAST_VIDEOS = 'lastVideos',
  WATCHED_VIDEOS = 'watchedVideos',
  SUBSCRIPTION = 'subscription'
}

export type LocalStorageLastWatchedVideos = {
  path: string;
  time: number;
  hash: string;
  id: number;
};

export type LocalStorageWatchedVideos = {
  path: string;
  hash: string;
  ids: number[];
};

type MarkVideoAsWatched = {
  videoIndex: number;
  locationPath: string;
  currentPlaylistHash: string;
};

type WatchedVideosEntryOperationParams = {
  watchedVideosArray: Array<LocalStorageWatchedVideos>;
  videoIndex: number;
  locationPath: string;
  currentPlaylistHash: string;
};

export const setLastWatchedVideo = function (
  currentTime: number,
  videoIndex: number,
  locationPath: string,
  currentPlaylistHash: string
) {
  const currentVideo: LocalStorageLastWatchedVideos = {
    path: locationPath,
    hash: currentPlaylistHash,
    time: currentTime,
    id: videoIndex
  };
  const lastWatchedVideos = localStorage.getItem(LocalStorageVariables.LAST_VIDEOS);
  if (lastWatchedVideos === null) {
    // create new variable in localStorage for new user
    localStorage.setItem(LocalStorageVariables.LAST_VIDEOS, JSON.stringify([currentVideo]));
  } else {
    // check if variable for current path/microsite already exists in localStorage no matter the hash (override outdated content)
    const watchedVideosArray = safeJSONParse<Array<LocalStorageLastWatchedVideos>>(lastWatchedVideos) || [];
    const lastWatchedVideoOnMicrosite = watchedVideosArray.find(
      (item: LocalStorageLastWatchedVideos) => item.path === locationPath
    );
    if (lastWatchedVideoOnMicrosite !== undefined) {
      const updatedLastWatchedVideo = watchedVideosArray.map((item: LocalStorageLastWatchedVideos) => {
        return item.path === locationPath
          ? { path: item.path, time: currentTime, id: videoIndex, hash: currentPlaylistHash }
          : item;
      });
      localStorage.setItem(LocalStorageVariables.LAST_VIDEOS, JSON.stringify(updatedLastWatchedVideo));
    } else {
      localStorage.setItem(LocalStorageVariables.LAST_VIDEOS, JSON.stringify([...watchedVideosArray, currentVideo]));
    }
  }
};

const createNewWatchedVideosEntry = (video: LocalStorageWatchedVideos): void => {
  localStorage.setItem(LocalStorageVariables.WATCHED_VIDEOS, JSON.stringify([video]));
};

const updateExistingWatchedVideosEntryWithNewPage = (
  watchedVideosArray: Array<LocalStorageWatchedVideos>,
  newVideo: LocalStorageWatchedVideos
): void => {
  localStorage.setItem(LocalStorageVariables.WATCHED_VIDEOS, JSON.stringify([...watchedVideosArray, newVideo]));
};

const updateExistingWatchedVideosEntry = ({
  watchedVideosArray,
  videoIndex,
  locationPath,
  currentPlaylistHash
}: WatchedVideosEntryOperationParams): void => {
  const updatedWatchedVideos = watchedVideosArray.map((item: LocalStorageWatchedVideos) => {
    const uniqueIds = [...new Set([...item.ids, videoIndex])];
    return item.path === locationPath ? { path: item.path, ids: uniqueIds, hash: currentPlaylistHash } : item;
  });
  localStorage.setItem(LocalStorageVariables.WATCHED_VIDEOS, JSON.stringify(updatedWatchedVideos));
};

const overrideOutdatedVideosWatchedEntry = ({
  watchedVideosArray,
  videoIndex,
  locationPath,
  currentPlaylistHash
}: WatchedVideosEntryOperationParams): void => {
  const newWatchedVideos = watchedVideosArray.map((item: LocalStorageWatchedVideos) => {
    return item.path === locationPath ? { path: item.path, ids: [videoIndex], hash: currentPlaylistHash } : item;
  });
  localStorage.setItem(LocalStorageVariables.WATCHED_VIDEOS, JSON.stringify(newWatchedVideos));
};

export const markVideoAsWatched = ({ videoIndex, locationPath, currentPlaylistHash }: MarkVideoAsWatched): void => {
  const currentVideo: LocalStorageWatchedVideos = {
    path: locationPath,
    hash: currentPlaylistHash,
    ids: [videoIndex]
  };
  const watchedVideos = localStorage.getItem(LocalStorageVariables.WATCHED_VIDEOS);
  if (watchedVideos === null) {
    createNewWatchedVideosEntry(currentVideo);
  } else {
    // check if variable for current path/microsite already exists in localStorage
    const watchedVideosArray = safeJSONParse<Array<LocalStorageWatchedVideos>>(watchedVideos) || [];
    const watchedVideosOnMicrosite = watchedVideosArray.find(
      (item: LocalStorageWatchedVideos) => item.path === locationPath
    );
    if (watchedVideosOnMicrosite !== undefined && watchedVideosOnMicrosite.hash === currentPlaylistHash) {
      updateExistingWatchedVideosEntry({ watchedVideosArray, videoIndex, locationPath, currentPlaylistHash });
    } else if (watchedVideosOnMicrosite !== undefined && watchedVideosOnMicrosite.hash !== currentPlaylistHash) {
      overrideOutdatedVideosWatchedEntry({ watchedVideosArray, videoIndex, locationPath, currentPlaylistHash });
    } else {
      updateExistingWatchedVideosEntryWithNewPage(watchedVideosArray, currentVideo);
    }
  }
};
