import React, { ReactElement } from 'react';
import { VideoDescriptionInteface } from './videoDescription.interface';
import styles from './videoDescription.module.scss';

export const VideoDescriptionComponent = ({ content, currentVideoIndex }: VideoDescriptionInteface): ReactElement => {
  return (
    <div className={styles.container}>
      {content.playlist[currentVideoIndex]?.title && <h2>{content.playlist[currentVideoIndex].title}</h2>}
      {content.playlist[currentVideoIndex]?.description && <p>{content.playlist[currentVideoIndex].description}</p>}
    </div>
  );
};
