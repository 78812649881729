import * as React from 'react';
import { useContent } from '../helpers';
import { FooterLinksComponent } from '../components/footerLinks/footerLinks.component';
import { NewsletterButtonComponent } from '../components/newsletterButton/newsletterButton.component';
import { PoweredByComponent } from '../components/poweredBy/poweredBy.component';
import styles from './layout.module.scss';

export type FooterProps = {
  setIsPopupSubscribeOpen: Function;
  isSubscribed: boolean;
};

const Footer: React.FC<FooterProps> = ({ setIsPopupSubscribeOpen, isSubscribed }) => {
  const { content } = useContent();
  const onButtonClick = (): void => {
    setIsPopupSubscribeOpen(true);
  };

  return (
    <div className={styles.footer}>
      <div className={styles.desktopFooter}>
        <FooterLinksComponent socials={content.socialMedia} links={content.aboutList} />
        {!isSubscribed && <NewsletterButtonComponent onClick={onButtonClick} />}
        <PoweredByComponent />
      </div>
      <div className={styles.mobileFooter}>
        {!isSubscribed && <NewsletterButtonComponent onClick={onButtonClick} />}
        <FooterLinksComponent socials={content.socialMedia} links={content.aboutList} />
        <PoweredByComponent />
      </div>
    </div>
  );
};

export default Footer;
