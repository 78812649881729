import { hotjarScriptDevelopment, hotjarScriptProduction } from './head-tag.const';
import { isProduction } from '../is-production';

export type TagToLoad = {
  innerHTML?: string;
  src?: string;
  type: string;
};

export class HeadTagsBuilder {
  private readonly tagsToLoad: TagToLoad[];

  private readonly isProd = isProduction();

  constructor() {
    this.tagsToLoad = [];
    this.build();
  }

  public get instance(): TagToLoad[] {
    return this.tagsToLoad;
  }

  private build(): this {
    this.buildHotjarTag();
    return this;
  }

  private buildHotjarTag(): this {
    const hotjarScript = this.isProd ? hotjarScriptProduction : hotjarScriptDevelopment;
    const hotjarTag = {
      type: 'text/javascript',
      innerHTML: hotjarScript
    };
    this.tagsToLoad.push(hotjarTag);
    return this;
  }
}
