import styles from './adBanner.module.scss';
import React, { useMemo } from 'react';
import classNames from 'classnames';

type AdBannerProps = {
  adBannerRedirect?: string;
  adBannerImg: string;
};

export const AdBanner: React.FC<AdBannerProps> = ({ adBannerImg, adBannerRedirect }) => {
  const enableAdLink = useMemo<boolean>(() => !!adBannerRedirect && !!adBannerImg, [adBannerRedirect, adBannerImg]);
  return (
    <div className={styles.wrapper}>
      {enableAdLink ? (
        <a
          className={classNames(styles.container, styles.adLink)}
          href={adBannerRedirect}
          target="_blank"
          rel="noreferrer"
        >
          {adBannerImg && <img src={adBannerImg} alt="advertisement" className={styles.adBanner} />}
        </a>
      ) : (
        <div className={styles.container}>
          {adBannerImg && <img src={adBannerImg} alt="advertisement" className={styles.adBanner} />}
        </div>
      )}
    </div>
  );
};
