import styles from './notFound.module.scss';

export const NotFound: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.code}>404</h1>
      <h2 className={styles.message}>Page not found</h2>
    </div>
  );
};
