import * as React from 'react';
import { LocalStorageLastWatchedVideos, LocalStorageVariables } from '../../pages/home/utils';
import styles from './popups.module.scss';
import { useLocation } from 'react-router';
import { safeJSONParse } from '../../helpers';

type PopupResumeVideoOpenProps = {
  setIsPopupResumeVideoOpen: Function;
  setResumeVideoProperties: Function;
  currentPlaylistHash: string;
};

const PopupResumeVideo: React.FunctionComponent<PopupResumeVideoOpenProps> = ({
  setIsPopupResumeVideoOpen,
  setResumeVideoProperties,
  currentPlaylistHash
}) => {
  const location = useLocation();

  const handleResumeVideo = function () {
    const localStorageLastVideos: null | string = localStorage.getItem(LocalStorageVariables.LAST_VIDEOS);
    if (localStorageLastVideos !== null) {
      safeJSONParse<Array<LocalStorageLastWatchedVideos>>(localStorageLastVideos)?.forEach(
        (item: LocalStorageLastWatchedVideos) => {
          if (item.path === location.pathname && item.hash === currentPlaylistHash) {
            setResumeVideoProperties({ id: item.id, time: item.time });
          }
        }
      );
    }
    setIsPopupResumeVideoOpen(false);
  };
  return (
    <div className={styles.popupResumeVideo}>
      <p>Start from where you left off?</p>
      <div className={styles.buttonsWrapper}>
        <button type="button" onClick={() => setIsPopupResumeVideoOpen(false)}>
          No, thanks
        </button>
        <button type="button" onClick={() => handleResumeVideo()}>
          Yes
        </button>
      </div>
    </div>
  );
};

export default PopupResumeVideo;
