//TODO: replace example type with proper event
export enum AnalyticsTypesEnum {
  PLAYER = 'Player',
  TIME_TRACKING = 'Time_tracking',
  PAGE = 'Page'
}

export enum AnalyticsPlayerEnum {
  VIDEO_BUTTON_NEXT = 'video_button_next',
  VIDEO_BUTTON_PREV = 'video_button_previous',
  VIDEO_BUTTON_TIME_FORWARD = 'video_button_time_forward',
  VIDEO_BUTTON_TIME_BACKWARD = 'video_button_time_backward',
  VIDEO_OPENED = 'video_opened'
}

export enum AnalyticsTrackTimeEnum {
  VIDEO_VIEWED = 'video_viewed',
  VIDEO_WATCHED = 'video_watched',
  VIDEO_CHANGED = 'video_changed',
  PLAYBACK_PAUSED = 'playback_paused',
  PLAYBACK_STARTED = 'playback_started'
}

export enum AnalyticsBestEffortEnum {
  PAGE_UNLOAD = 'page_unload'
}
