import * as React from 'react';
import styles from './layout.module.scss';

type HeaderProps = {
  partnersLogo: string;
};

const Header: React.FunctionComponent<HeaderProps> = ({ partnersLogo }) => {
  return <div className={styles.header}>{partnersLogo && <img src={partnersLogo} alt="Logo" />}</div>;
};

export default Header;
