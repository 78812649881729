import * as React from 'react';
import { Route, Routes } from 'react-router';
import Footer from './Footer';
import Header from './Header';
import Home from '../pages/home';
import { GradientBarComponent } from '../components/gradientBar/gradientBar.component';
import styles from './layout.module.scss';
import { ResumeVideoProperties } from '../App';
import { useContent } from '../helpers';

export type LayoutProps = {
  isSubscribed: boolean;
  setIsPopupSubscribeOpen: Function;
  isPopupSubscribeOpen: boolean;
  resumeVideoProperties: ResumeVideoProperties;
  setResumeVideoProperties: Function;
  isPopupResumeVideoOpen: boolean;
};

export const Layout: React.FC<LayoutProps> = ({
  isSubscribed,
  setIsPopupSubscribeOpen,
  isPopupSubscribeOpen,
  resumeVideoProperties,
  setResumeVideoProperties,
  isPopupResumeVideoOpen
}) => {
  const { content } = useContent();

  return (
    <div
      className={styles.container}
      style={{ '--highlightColor': content.playingContentItemColor } as React.CSSProperties}
    >
      <Header partnersLogo={content.partnersLogo} />
      <Routes>
        <Route
          path="*"
          element={
            <Home
              setIsPopupSubscribeOpen={setIsPopupSubscribeOpen}
              isPopupSubscribeOpen={isPopupSubscribeOpen}
              resumeVideoProperties={resumeVideoProperties}
              setResumeVideoProperties={setResumeVideoProperties}
              isPopupResumeVideoOpen={isPopupResumeVideoOpen}
            />
          }
        />
      </Routes>
      <Footer setIsPopupSubscribeOpen={setIsPopupSubscribeOpen} isSubscribed={isSubscribed} />
      <GradientBarComponent />
    </div>
  );
};
