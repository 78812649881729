import React, { useEffect, useState } from 'react';
import styles from './gradientBar.module.scss';

export const GradientBarComponent = () => {
  const [shouldDisplayGradientBar, setShouldDisplayGradientBar] = useState(true);

  useEffect(() => {
    const onScroll = () => {
      setShouldDisplayGradientBar(document.documentElement.scrollTop === 0);
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return shouldDisplayGradientBar ? <div className={styles.container} /> : null;
};
