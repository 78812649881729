import { Slider } from '@mui/material';
import { useEffect, useState } from 'react';
import { PlaylistScrollInterface } from './playlistScroll.interface';
import styles from './playlistScroll.module.scss';
import { sliderStyles } from './playlistScroll.styles';

export const PlaylistScrollComponent = ({ playlistRef }: PlaylistScrollInterface) => {
  const [sliderValue, setSliderValue] = useState(0);

  //calculate max scroll length, to apply it to custom slide
  const scrollLimit = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  //check if slider is needed, calculate playlist height against page height
  const showScroll = playlistRef?.current
    ? window.innerHeight <
      playlistRef?.current?.clientHeight + playlistRef?.current?.getBoundingClientRect().top + window.scrollY
    : false;

  const onScroll = (offset: number): void => {
    window.scroll({ left: 0, top: offset });
  };

  useEffect((): (() => void) => {
    const scrollHandler = (): void => {
      setSliderValue(scrollLimit - window.scrollY);
    };
    document.addEventListener('scroll', scrollHandler);

    return (): void => {
      document.removeEventListener('scroll', scrollHandler);
    };
  }, [scrollLimit]);

  return (
    <div className={styles.container}>
      {showScroll ? (
        <Slider
          aria-label="time-indicator"
          size="small"
          value={sliderValue}
          min={0}
          step={1}
          max={scrollLimit}
          orientation="vertical"
          track="inverted"
          onChange={(_, value) => {
            onScroll(scrollLimit - (value as number));
            setSliderValue(scrollLimit - window.scrollY);
          }}
          sx={sliderStyles(playlistRef)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
