import React, { ReactElement } from 'react';
import styles from './copyrights.module.scss';

export const CopyrightsComponent = (): ReactElement => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.container}>
      <p>{`Copyrights ${currentYear}`}</p>
    </div>
  );
};
