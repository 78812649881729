type Source = {
  src: string;
  type: string;
};

export enum SOCIAL_MEDIA_PROVIDER {
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  TWITTER = 'Twitter',
  YOUTUBE = 'Youtube',
  PINTEREST = 'Pinterest',
  TIKTOK = 'Tiktok'
}

export type SocialMedia = {
  url: string;
  provider: SOCIAL_MEDIA_PROVIDER;
};

export type AboutLink = {
  url: string;
  name: string;
};

export type RawVideoSource = {
  trackId: string;
  title?: string;
  description?: string;
  totalVideoTime?: string;
};

export type PlaylistItem = {
  sources: Array<Source>;
  title: string;
  description: string;
  totalVideoTime: string;
};

export type ContentEntry = {
  path: string;
  autoPlay: boolean;
  muteFromBeginning: boolean;
  skipButtonFeature: boolean;
  fullscreenMode: boolean;
  speedUpMode: boolean;
  jumpFeature: boolean;
  jumpTime: number;
  remainingTimeVisibility: boolean;
  partnersLogo: string;
  playingContentItemColor: string;
  playlistTitle: string;
  itemsPerPage: number;
  adsBanner: string;
  adBannerRedirect?: string;
  klaviyoNewsletterId: string;
  playlist: Array<RawVideoSource>;
  socialMedia: Array<SocialMedia>;
  aboutList: Array<AboutLink>;
};
