import * as rdd from 'react-device-detect';
import { DeviceInfo } from '../types';
import { DeviceTypeEnum, OsTypeEnum } from './enums';

export const detectDeviceInfo = (): DeviceInfo => {
  const deviceData = rdd.deviceDetect(rdd.getUA);
  let deviceType = rdd.mobileVendor;
  const mobileModel = rdd.mobileModel;
  const osName = deviceData.osName;

  if ([OsTypeEnum.WINDOWS, OsTypeEnum.LINUX].includes(osName)) {
    deviceType = DeviceTypeEnum.DESKTOP;
  }
  return {
    osName,
    deviceType,
    mobileModel
  };
};
