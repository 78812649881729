import * as React from 'react';
import qs from 'querystring';

import styles from './popups.module.scss';
import { ClosePopUpIcon } from './../../assets/icons/index';
import { registerUserData } from '../../helpers/tracking/analyticsManager';
import { useMemo } from 'react';
import { LocalStorageVariables } from '../../pages/home/utils';
import { LocalStorageSubscribeEntry } from '../../types';
import { useLocation } from 'react-router';
import { safeJSONParse } from '../../helpers';

type PopupSubscribeProps = {
  setIsPopupSubscribeOpen: Function;
  klaviyoNewsletterId: string;
};

type SubscribeFormFields = {
  name: { value: string; valid: boolean; touched: boolean };
  email: { value: string; valid: boolean; touched: boolean };
  phone: { value: string | null; valid: boolean; touched: boolean };
};

const headers = {
  'content-type': 'application/x-www-form-urlencoded',
  'cache-control': 'no-cache'
};

const KLAVIYO_SUBSCRIPTION_LINK = 'https://manage.kmail-lists.com/ajax/subscriptions/subscribe?a=QU2PFj&g=';

const PopupSubscribe: React.FunctionComponent<PopupSubscribeProps> = ({
  setIsPopupSubscribeOpen,
  klaviyoNewsletterId
}) => {
  const location = useLocation();
  const [subscribeRequestResult, setSubscribeRequestResult] = React.useState<null | 'success' | 'error'>(null);
  const newsletterLink = useMemo(() => KLAVIYO_SUBSCRIPTION_LINK + klaviyoNewsletterId, [klaviyoNewsletterId]);
  const [dataForm, setDataForm] = React.useState<SubscribeFormFields>({
    name: {
      value: '',
      valid: false,
      touched: false
    },
    email: {
      value: '',
      valid: false,
      touched: false
    },
    phone: {
      value: null,
      valid: false,
      touched: false
    }
  });

  const onInputChange = function (inputName: string, value: string) {
    if (inputName === 'name' && typeof value === 'string') {
      setDataForm({ ...dataForm, name: { value: value, valid: value.length > 1, touched: dataForm.name.touched } });
    } else if (inputName === 'email' && typeof value === 'string') {
      // eslint-disable-next-line
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
      const isEmailValid = emailRegex.test(value);
      setDataForm({ ...dataForm, email: { value: value, valid: isEmailValid, touched: dataForm.name.touched } });
    } else if (inputName === 'phone') {
      const phoneRegex = /^\d+$/;
      const isPhoneValid = phoneRegex.test(value);

      setDataForm({
        ...dataForm,
        phone: {
          value: value,
          valid: isPhoneValid,
          touched: false
        }
      });
    }
  };

  const onInputFocusOut = function (inputName: string) {
    if (inputName === 'name') {
      setDataForm({ ...dataForm, name: { ...dataForm.name, touched: true } });
    } else if (inputName === 'email') {
      setDataForm({ ...dataForm, email: { ...dataForm.email, touched: true } });
    } else if (inputName === 'phone') {
      setDataForm({ ...dataForm, phone: { ...dataForm.phone, touched: true } });
    }
  };

  const handleClosePopup = () => {
    setIsPopupSubscribeOpen(false);
    setSubscribeRequestResult(null);
  };

  const addUserToNewstletter = () => {
    registerUserData({
      name: dataForm.name.value,
      email: dataForm.email.value,
      phone: dataForm.phone.value || undefined
    });
    fetch(newsletterLink, {
      method: 'POST',
      headers,
      body: qs.stringify({
        fields: '$first_name,$phone_number',
        $first_name: dataForm.name.value,
        email: dataForm.email.value,
        $phone_number: dataForm.phone.value
      })
    })
      .then((response) => response.json())
      .then(() => setSubscribeRequestResult('success'))
      .then(() => {
        try {
          const subscriptionEntry = localStorage.getItem(LocalStorageVariables.SUBSCRIPTION);
          const isEntryNull = subscriptionEntry === null;
          let subscriptionEntryArray: Array<LocalStorageSubscribeEntry> = [];
          if (!isEntryNull) {
            subscriptionEntryArray = safeJSONParse<Array<LocalStorageSubscribeEntry>>(subscriptionEntry) || [];
          }
          const locationSubscriptionEntryIndex = subscriptionEntryArray.findIndex(
            (item: LocalStorageSubscribeEntry): boolean => item.path === location.pathname
          );
          subscriptionEntryArray[locationSubscriptionEntryIndex].signedIn = true;
          localStorage.setItem(LocalStorageVariables.SUBSCRIPTION, JSON.stringify(subscriptionEntryArray));
        } catch {}
      })
      .catch(() => setSubscribeRequestResult('error'));

    setDataForm({
      name: {
        value: '',
        valid: false,
        touched: false
      },
      email: {
        value: '',
        valid: false,
        touched: false
      },
      phone: {
        value: null,
        valid: false,
        touched: false
      }
    });
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.popupSubcribe}>
        {subscribeRequestResult === null && (
          <>
            <h2>SIGN UP FOR MORE</h2>
            <p>Enter your name, email and/or phone number below and keep up with the latest!</p>
            <form>
              <input type="hidden" name="g" value={klaviyoNewsletterId} />
              <label>
                <input
                  autoComplete="off"
                  type="text"
                  name="name"
                  placeholder="your name and surname (required)"
                  onChange={(e) => onInputChange(e.target.name, e.target.value)}
                  onBlur={(e) => onInputFocusOut(e.target.name)}
                />
                {dataForm.name.touched && !dataForm.name.valid && <p>Please enter your name.</p>}
              </label>
              <label>
                <input
                  autoComplete="off"
                  type="email"
                  name="email"
                  placeholder="your e-mail (required)"
                  onChange={(e) => onInputChange(e.target.name, e.target.value)}
                  onBlur={(e) => onInputFocusOut(e.target.name)}
                />
                {dataForm.email.touched && !dataForm.email.valid && <p>Please enter valid mail.</p>}
              </label>
              <label>
                <input
                  autoComplete="off"
                  type="tel"
                  name="phone"
                  placeholder="your phone number (optional)"
                  onChange={(e) => onInputChange(e.target.name, e.target.value)}
                  onBlur={(e) => onInputFocusOut(e.target.name)}
                />
                {dataForm.phone.touched && !dataForm.phone.valid && <p>Phone number is invalid.</p>}
              </label>
              <button
                type="submit"
                disabled={!dataForm.email.valid || !dataForm.name.valid}
                onClick={() => addUserToNewstletter()}
              >
                Subscribe
              </button>
            </form>
            <button type="button" className={styles.closePopup} onClick={() => handleClosePopup()}>
              <ClosePopUpIcon />
            </button>
          </>
        )}
        {subscribeRequestResult === 'success' && (
          <>
            <p>Thank you. Please enjoy the content.</p>
            <button type="button" className={styles.closePopup} onClick={() => handleClosePopup()}>
              <ClosePopUpIcon />
            </button>
          </>
        )}
        {subscribeRequestResult === 'error' && (
          <>
            <p>Something went wrong. Try again later.</p>
            <button type="button" className={styles.closePopup} onClick={() => handleClosePopup()}>
              <ClosePopUpIcon />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PopupSubscribe;
