import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga';
import { ContentEntry, DeviceInfo } from '../../types';
import { AnalyticsBestEffortEnum, AnalyticsPlayerEnum, AnalyticsTrackTimeEnum, AnalyticsTypesEnum } from './enums';
import {
  AnalyticsUserData,
  SendBestEffortEventParams,
  SendPlayerEventParams,
  SendTrackTimeEventParams,
  TrackEventHelper,
  TrackPageEvents
} from './types';
import { generateGACustomDimensions } from './googleAnalitics';

const mapParamsToLabel = (params: Record<string, any>) => {
  return Object.entries(params).reduce((acc, [key, value]) => acc + `${key}:${JSON.stringify(value)}, `, '');
};

export const campaignParams = (location: Location | any): void => {
  const params: Record<string, any> = {};
  const setOnceParams: Record<string, any> = {};
  const campaignKeywords = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
  const queryParams = new URLSearchParams(location.search.substring(1));

  const filteredParams: Array<Array<string>> = [];
  Array.from(queryParams.entries()).forEach((objectEntry): void => {
    const isParamsFromCampaign = campaignKeywords.find((keyword) => keyword === objectEntry[0]);
    if (isParamsFromCampaign) {
      filteredParams.push(objectEntry);
    }
  });

  filteredParams?.forEach((paramsArray): void => {
    if (paramsArray?.[0]) {
      if (paramsArray[0] === 'utm_source') {
        params[`${paramsArray[0]} [last touch]`] = paramsArray[1];
        setOnceParams[`${paramsArray[0]} [first touch]`] = paramsArray[1];
      }
      params[paramsArray[0]] = paramsArray[1];
    }
  });

  for (const propName in params) {
    const isValueEmpty =
      params[propName] === null ||
      params[propName] === undefined ||
      params[propName] === '' ||
      params[propName] === 'undefined';

    if (isValueEmpty) {
      delete params[propName];
    }
  }

  if (Object.keys(params).length) {
    try {
      mixpanel.people.set(params);
      mixpanel.people.set_once(setOnceParams);
      mixpanel.register(params);
      mixpanel.identify();
    } catch {}
  }
};

export const registerUserData = (analyticsUserData: AnalyticsUserData): void => {
  try {
    mixpanel.people.set(analyticsUserData);
    mixpanel.identify();
  } catch {}
};

export const registerSuper = (content: ContentEntry, deviceInfo: DeviceInfo): void => {
  try {
    const propsToRegister = { ...content, ...deviceInfo };
    mixpanel.register(propsToRegister);
    ReactGA.set(generateGACustomDimensions(propsToRegister));
  } catch {}
};

export const trackPageEvents = ({ eventName, params }: TrackPageEvents): void => {
  try {
    mixpanel.track(eventName, params);
    ReactGA.pageview(eventName, params);
  } catch {}
};

export const trackEventHelper = ({ eventName, category, params, isBestEffort = false }: TrackEventHelper): void => {
  try {
    if (isBestEffort) {
      mixpanel.track(eventName, { ...params, category }, { transport: 'sendBeacon', send_immediately: true });
      ReactGA.event({
        category,
        action: eventName,
        label: mapParamsToLabel(params),
        transport: 'beacon'
      });
    } else {
      mixpanel.track(eventName, { ...params, category });
      ReactGA.event({
        category,
        action: eventName,
        label: mapParamsToLabel(params)
      });
    }
  } catch {}
};

export function sendPlayerEvent(eventName: AnalyticsPlayerEnum, params?: SendPlayerEventParams): void {
  switch (eventName) {
    case AnalyticsPlayerEnum.VIDEO_BUTTON_NEXT:
    case AnalyticsPlayerEnum.VIDEO_BUTTON_PREV:
    case AnalyticsPlayerEnum.VIDEO_BUTTON_TIME_FORWARD:
    case AnalyticsPlayerEnum.VIDEO_BUTTON_TIME_BACKWARD:
    case AnalyticsPlayerEnum.VIDEO_OPENED:
      trackEventHelper({ eventName: eventName, category: AnalyticsTypesEnum.PLAYER, params });
      break;
    default:
      break;
  }
}

export function sendTrackTimeEvent(eventName: AnalyticsTrackTimeEnum, params?: SendTrackTimeEventParams): void {
  switch (eventName) {
    case AnalyticsTrackTimeEnum.VIDEO_WATCHED:
    case AnalyticsTrackTimeEnum.VIDEO_VIEWED:
    case AnalyticsTrackTimeEnum.VIDEO_CHANGED:
    case AnalyticsTrackTimeEnum.PLAYBACK_PAUSED:
    case AnalyticsTrackTimeEnum.PLAYBACK_STARTED:
      trackEventHelper({ eventName: eventName, category: AnalyticsTypesEnum.TIME_TRACKING, params });
      break;
    default:
      break;
  }
}

export function sendBestEffortEvent(eventName: AnalyticsBestEffortEnum, params?: SendBestEffortEventParams): void {
  switch (eventName) {
    case AnalyticsBestEffortEnum.PAGE_UNLOAD:
      trackEventHelper({
        eventName: eventName,
        category: AnalyticsTypesEnum.PAGE,
        params,
        isBestEffort: true
      });
      break;
    default:
      break;
  }
}
