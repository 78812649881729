import ReactGA from 'react-ga';

export function initializeGA(): void {
  try {
    // eslint-disable-next-line
    // @ts-ignore
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
      debug: false,
      gaOptions: {
        alwaysSendReferrer: true
      }
    });
  } catch {}
}

export function sendExceptionEventGA(description: string, isCritical = false): void {
  try {
    ReactGA.exception({
      description,
      fatal: isCritical
    });
  } catch {}
}

export function generateGACustomDimensions(parameters: any): ReactGA.FieldsObject {
  let customDimensions: ReactGA.FieldsObject = {};
  Object.keys(parameters).forEach((key, index): void => {
    customDimensions[`dimension${index}`] = JSON.stringify({ [key]: parameters[key] });
  });
  return customDimensions;
}
