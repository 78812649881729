import React, { ReactElement } from 'react';
import listendotLogo from '../../assets/listendotLogoSquer.png';
import styles from './poweredBy.module.scss';

export const PoweredByComponent = (): ReactElement => {
  return (
    <div className={styles.container}>
      <p>powered by</p>
      <img alt="Listendot logo" src={listendotLogo} />
    </div>
  );
};
