import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { CopyrightsComponent } from '../copyrights/copyrights.component';
import { FooterLinksInterface } from './footerLinks.interface';
import styles from './footerLinks.module.scss';
import { AboutLink, SOCIAL_MEDIA_PROVIDER } from '../../types';
import { FacebookIcon, InstagramIcon, PinterestIcon, TiktokIcon, TwitterIcon, YoutubeIcon } from '../../assets/socials';
import { SvgIcon } from '@mui/material';

export const FooterLinksComponent = ({ socials, links }: FooterLinksInterface): ReactElement => {
  const [linksToRender, setLinksToRender] = useState<AboutLink[][]>([]);

  const onLinkClick = (url: string): void => {
    window.open(url);
  };

  const getIcon = (provider: SOCIAL_MEDIA_PROVIDER): JSX.Element => {
    let icon: JSX.Element;
    switch (provider) {
      case SOCIAL_MEDIA_PROVIDER.FACEBOOK:
        icon = <FacebookIcon />;
        break;
      case SOCIAL_MEDIA_PROVIDER.INSTAGRAM:
        icon = <InstagramIcon />;
        break;
      case SOCIAL_MEDIA_PROVIDER.YOUTUBE:
        icon = <YoutubeIcon />;
        break;
      case SOCIAL_MEDIA_PROVIDER.TWITTER:
        icon = <TwitterIcon />;
        break;
      case SOCIAL_MEDIA_PROVIDER.PINTEREST:
        icon = <PinterestIcon />;
        break;
      case SOCIAL_MEDIA_PROVIDER.TIKTOK:
        icon = <TiktokIcon />;
        break;
    }
    return icon;
  };

  const renderLinks = (): ReactElement[] | ReactElement => {
    if (!links.length) {
      return <></>;
    }
    return linksToRender.map((chunkElement, index) => {
      return (
        <div className={styles.links} key={`links_container_${index}`}>
          {chunkElement.map((el) => {
            return (
              <div className={styles.link} key={el.name} onClick={(): void => onLinkClick(el.url)}>
                <p>{el.name}</p>
              </div>
            );
          })}
        </div>
      );
    });
  };

  const renderSocials = (): ReactElement[] => {
    return socials.map(({ provider, url }, index) => {
      return (
        <SvgIcon
          viewBox="0 0 96 96"
          strokeWidth="5"
          className={styles.socialIcon}
          key={`social_icon_${index}`}
          onClick={(): void => onLinkClick(url)}
        >
          {getIcon(provider)}
        </SvgIcon>
      );
    });
  };

  useEffect((): void => {
    const chunks = [];
    let chunk: AboutLink[] = [];

    for (let i = 0; i < links.length; i++) {
      chunk.push(links[i]);
      if (i === 2 || i === links.length - 1) {
        chunks.push(chunk);
        chunk = [];
      }
    }
    setLinksToRender(chunks);
  }, [links]);

  return (
    <div className={styles.container}>
      <div className={styles.socials}>{renderSocials()}</div>
      {renderLinks()}
      <CopyrightsComponent />
    </div>
  );
};
