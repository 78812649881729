import { PlaylistScrollComponent } from '../playlistScroll/playlistScroll.component';
import styles from './playlist.module.scss';
import { ArrowLeft, ArrowRight } from './../../assets/icons';
import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { PlaylistItem } from '../../types';

export type PlaylistProps = {
  playlist: Array<PlaylistItem>;
  setPlaylistItem: (item: number) => void;
  currentVideoIndex: number;
  watchedVideos: Array<number>;
  playlistTitle: string;
  itemsPerPage: number;
};

type IndexedPlaylist = Array<PlaylistItem & { index: number }>;

export const Playlist: React.FC<PlaylistProps> = ({
  setPlaylistItem,
  playlist,
  currentVideoIndex,
  watchedVideos,
  playlistTitle,
  itemsPerPage
}) => {
  const playlistRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const paginationPages: IndexedPlaylist[] = [];
  let lastPageIndex: number;
  let pageCounter: number = 0;

  const indexedPlaylist: IndexedPlaylist = playlist.map((item, index) => {
    return {
      sources: item.sources,
      title: item.title,
      description: item.description,
      totalVideoTime: item.totalVideoTime,
      index: index
    };
  });

  indexedPlaylist.forEach((item, index): void => {
    if (paginationPages.length === 0) {
      paginationPages.push([item]);
    } else if (index < pageCounter * itemsPerPage + itemsPerPage) {
      paginationPages[pageCounter] = [...paginationPages[pageCounter], item];
    } else {
      pageCounter++;
      paginationPages.push([item]);
    }
  });

  lastPageIndex = paginationPages.length - 1;
  const createPaginationButton = function (index: number): JSX.Element {
    return (
      <button
        key={index}
        className={`${styles.paginationItem} ${index === currentPage && styles.paginationItemCurrent}`}
        onClick={() => setCurrentPage(index)}
        type="button"
      >
        {index + 1}
      </button>
    );
  };

  // keep current page
  useEffect(() => {
    const shouldNotChangePage = paginationPages[currentPage].find((item) => {
      return item.index === currentVideoIndex;
    });
    if (shouldNotChangePage === undefined) {
      paginationPages.forEach((page, index) => {
        page.forEach((item) => {
          if (item.index === currentVideoIndex) {
            setCurrentPage(index);
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVideoIndex]);

  return (
    <div className={styles.container}>
      <PlaylistScrollComponent playlistRef={playlistRef} />
      <div className={styles.playlistHeader}>
        <div className={styles.title}>
          <p>{playlistTitle}</p>
        </div>
      </div>
      <div className={styles.playlistBody} ref={playlistRef}>
        {paginationPages[currentPage].map((item) => (
          <div
            className={styles.playlistItem}
            onClick={() => setPlaylistItem(item.index)}
            key={item.index}
            id={`list-item-${item.index}`}
          >
            <div
              className={classNames(
                styles.itemDetails,
                watchedVideos.includes(item.index) && styles.itemDetailsWatched,
                item.index === currentVideoIndex && styles.itemDetailsCurrentVideo
              )}
            >
              <h4>{item.title}</h4>
              <div className={styles.playlistItemDescription}>
                <p className={styles.description}>{item.description}</p>
                <p className={styles.totalTime}>{item.totalVideoTime}</p>
              </div>
            </div>
          </div>
        ))}
        {playlist.length > itemsPerPage && (
          <div className={styles.pagination}>
            {paginationPages.length > 2 && (
              <button
                className={styles.paginationItem}
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 0 || paginationPages.length < 4}
                type="button"
              >
                <ArrowLeft />
              </button>
            )}
            {paginationPages.length <= 5 ? (
              paginationPages.map((item, index) => {
                return createPaginationButton(index);
              })
            ) : (
              <>
                {createPaginationButton(0)}

                {[0, 1, lastPageIndex - 1, lastPageIndex].includes(currentPage) ? (
                  createPaginationButton(1)
                ) : (
                  <p>...</p>
                )}

                {[0, 1, lastPageIndex - 1, lastPageIndex].includes(currentPage) ? (
                  <p>...</p>
                ) : (
                  createPaginationButton(currentPage)
                )}

                {[0, 1, lastPageIndex - 2, lastPageIndex - 1, lastPageIndex].includes(currentPage) ? (
                  createPaginationButton(lastPageIndex - 1)
                ) : (
                  <p>...</p>
                )}

                {createPaginationButton(lastPageIndex)}
              </>
            )}
            {paginationPages.length > 2 && (
              <button
                className={styles.paginationItem}
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === lastPageIndex || paginationPages.length < 4}
                type="button"
              >
                <ArrowRight />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
