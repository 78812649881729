import { ContentEntry } from '../types';
import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { getMicrosites } from '../service/strapi/microsites-service';
import { defaultContent } from '../const';

export const useContent = (): { content: ContentEntry; isContentLoaded: boolean } => {
  const location = useLocation();
  const [content, setContent] = useState({ ...defaultContent });
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  useEffect(() => {
    setIsContentLoaded(false);
    const micrositePath = location.pathname === '/' ? 'homePage' : location.pathname.substring(1);
    getMicrosites(micrositePath)
      .then((r) => {
        if (r) {
          setContent({ ...defaultContent, ...r });
        }
      })
      .finally(() => setIsContentLoaded(true));
  }, [location]);

  return { content, isContentLoaded };
};
